import React from "react";
import PageLayout from "../../components/PageLayout";
import { FirstOrderTrendOptions, RankTrendOptions, ReOrderTrendOptions, VolumeTrendOptions } from "../Reports/TrendsConfig";
import WishCard from "../../components/WishCard";
import EmptyAnimation from "../../components/EmptyAnimation";
import animation from "../../assets/app-assets/images/lottie/NoDataAvailable.json";

export default function ReportsDash() {
  const distributor = JSON.parse(localStorage.getItem("distributor"));

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Reports & Trends", linkTo: "/reportsandtrends" });

  const publicReports = [
    {
      title: "Gross Revenue Report",
      icon: "la-chart-line",
      line1: "View the break-up of the revenue you received in terms of cash.",
      linkTo: "/commissionreports",
      linkToState: {},
    },
    {
      title: "Order Reports",
      icon: "la-shopping-cart",
      line1: "Monitor all types of orders here.",
      linkTo: "/orderreports",
      linkToState: {},
    },
    {
      title: "Bonus Report",
      icon: "la-money-bill",
      line1: "Access bonus-related reports and financial transactions.",
      linkTo: "/bonusreport",
      linkToState: {},
    },
  ];
  const wishCards = [
    {
      title: "VOTM Order Report",
      icon: "la-shopping-cart",
      line1: "Retrieve the orders delivered through your VOTM.",
      linkTo: "/votmorderreports",
      linkToState: {},
    },
    {
      title: "Co-applicant Bonus Report",
      icon: "la-gift",
      line1: "Review the tenure and payment details of your co-applicant revenue.",
      linkTo: "/co-applicantbonusreport",
      linkToState: {},
    },
    {
      title: "Miscellaneous Reports",
      icon: "la-file-alt",
      line1: "Review deductions and adjustments from your payments.",
      linkTo: "/deductionsandadjustments",
      linkToState: {},
    },
    {
      title: "Enrollment Reports",
      icon: "la-user-plus",
      line1: "Retrieve the details of new VRs in your network and explore growth potential.",
      linkTo: "/enrollmentreport",
      linkToState: {},
    },
    {
      title: "My Voucher Report ",
      icon: "la-money-bill",
      line1: "View reports on vouchers and financial transactions. Analyze voucher usage and transactions. ",
      linkTo: "/myvoucherreport",
      linkToState: {},
    },
    {
      title: "Volume Report",
      icon: "la-chart-bar",
      line1: "Monitor the volume in your network and the break-ups in detail.",
      linkTo: "/volumereport",
      linkToState: {},
    },
    {
      title: "Team Report",
      icon: "la-users",
      line1: "Retrieve and review the ranks and special status in your network.",
      linkTo: "/teamreport",
      linkToState: {},
    },
    {
      title: "Sponsor Distributor Report",
      icon: "la-users",
      line1: "Get detailed information about the distributors you have sponsored.Review their activities, sales performance, and achievements..",
      linkTo: "/sponsoreddistributorreport",
      linkToState: {},
    },
    {
      title: "Top Performers Report",
      icon: "la-bank",
      line1: "Access Top Performers performance under each sponsor",
      linkTo: "/topperformersreport",
      linkToState: {},
    },
    {
      title: "Customer Re-order leads",
      icon: "la-users",
      isNew: true,
      line1:
        "Get list of customers whose products might have been consumed by today. Use this option to view list of customers whose product might be finished within the next 4 days.",
      linkTo: "/customerreorderleads",
      linkToState: {},
    },

    {
      title: "Users yet to download Viva App",
      icon: "la-download",
      isNew: true,
      line1: "Get list of direct sellers who are yet to download the Indusviva mobile app in your team.",
      linkTo: "/mobileappteamcountreport",
      linkToState: {},
    },
    {
      title: "Storelink Pending Order Report.",
      icon: "la-users",
      isNew: true,
      line1: "fetches pending orders from storelink.",
      linkTo: "/storeorderpendingreport",
      linkToState: {},
    },
    {
      title: "DigiRockStar Achieved Report.",
      icon: "la-users",
      isNew: true,
      line1: "View the report of distributors achieving DigiRockstar targets.Analyze the performance and rewards of DigiRockstar achievers.",
      linkTo: "/digirockstarreport",
      linkToState: {},
    },
    {
      title: "DigiRockStar Achieved Monthly Report.",
      icon: "la-users",
      isNew: true,
      line1: "View the report of distributors achieving DigiRockstar targets in the current month.Analyze the performance and rewards of DigiRockstar achievers.",
      linkTo: "/digirockstarmonthlyreport",
      linkToState: {},
    },
  ];
  const trendReportList = [
    {
      title: "First Order Trend",
      icon: "la-chart-line",
      line1: "View the graphical representation of first order trends in your network.",
      linkTo: "/firstordertrend",
      linkToState: { options: FirstOrderTrendOptions },
    },
    {
      title: "Re-Order Trend",
      icon: "la-chart-line",
      line1: "View the graphical representation of re-order trends in your network.",
      linkTo: "/reordertrend",
      linkToState: { options: ReOrderTrendOptions },
    },
    {
      title: "Rank Trend",
      icon: "la-chart-line",
      line1: "Review your performance based on achieved vs. paid as ranks.",
      linkTo: "/ranktrend",
      linkToState: { options: RankTrendOptions },
    },
    {
      title: "Volume Trend",
      icon: "la-chart-line",
      line1: "Review and analyze the performance of your network in terms of volume. ",
      linkTo: "/volumetrend",
      linkToState: { options: VolumeTrendOptions },
    },
  ];

  const reportsNotYetReleased = [
    {
      title: "Distributor Activity Status Report",
      icon: "la-users",
      isNew: true,
      line1: "View the report of active and inactive distributors in your network for a certain week.",
      linkTo: "/activedistributorreport",
      linkToState: {},
    },
    {
      title: "Consistent Distributor Report",
      icon: "la-users",
      isNew: true,
      line1: "View the report of consistent distributors in your network for a certain week.",
      linkTo: "/consistentdistributorreport",
      linkToState: {},
    },
    {
      title: "Productive Distributor Report",
      icon: "la-users",
      isNew: true,
      line1: "View the report of productive distributors in your network for a certain week.",
      linkTo: "/productivedistributorreport",
      linkToState: {},
    },
  ];
  return (
    <PageLayout activeSideMenu="6" pageTitle="Reports & Trends" header="Reports" breadcrumbs={breadcrumbs}>
      <section className="row">
        {publicReports.map((card, index) => (
          <div className="col-md-4 col-sm-6" key={index}>
            <WishCard
              title={card.title}
              icon={card.icon}
              line1={card.line1}
              line2={card.line2}
              linkToState={card.linkToState}
              linkTo={card.linkTo}
              maxHeight={true}
              linkToModal={card.linkToModal}
            ></WishCard>
          </div>
        ))}
        {distributor?.distributor_rank?.rank_id > 1 &&
          wishCards.map((card, index) => (
            <div className="col-md-4 col-sm-6" key={index}>
              <WishCard
                title={card.title}
                icon={card.icon}
                line1={card.line1}
                line2={card.line2}
                linkToState={card.linkToState}
                linkTo={card.linkTo}
                maxHeight={true}
                linkToModal={card.linkToModal}
              ></WishCard>
            </div>
          ))}
        {distributor?.distributor_id == 1002 &&
          reportsNotYetReleased.map((card, index) => (
            <div className="col-md-4 col-sm-6" key={index}>
              <WishCard
                title={card.title}
                icon={card.icon}
                line1={card.line1}
                line2={card.line2}
                linkToState={card.linkToState}
                linkTo={card.linkTo}
                maxHeight={true}
                linkToModal={card.linkToModal}
              ></WishCard>
            </div>
          ))}
      </section>
      {distributor?.distributor_rank?.rank_id > 1 && (
        <>
          <h3>Trends</h3>
          <section className="row">
            {trendReportList.map((card, index) => (
              <div className="col-md-4 col-sm-6" key={index}>
                <WishCard
                  title={card.title}
                  icon={card.icon}
                  line1={card.line1}
                  line2={card.line2}
                  linkToState={card.linkToState}
                  linkTo={card.linkTo}
                  maxHeight={true}
                  linkToModal={card.linkToModal}
                ></WishCard>
              </div>
            ))}
          </section>{" "}
        </>
      )}
    </PageLayout>
  );
}
